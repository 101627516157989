import React from "react";
import featuresimg from "../assets/featuresimg.png";
// import './sectionTwo.css'

const SectionTwo = () => {
  return (
    <div>
      <div className="flex flex-col md:flex-row justify-center items-center mt-20 gap-5">
        {/* Left Div */}
        <div className="w-full md:w-1/2 text-center md:text-left">
          <img
            src={featuresimg}
            alt="Your Image"
            className="w-full md:w-3/3 mx-auto md:mx-0 mb-4 border-solid rounded-[35px] max-w-[450px]"
          />
        </div>

        {/* Right Div */}
        <div className="w-full md:w-1/2 text-center md:text-left text-white">
          <p className="mb-2 font-BoldMelo font-medium text-white text-[30px] tracking-[0.64px] leading-[normal] ">
            Streamline Business
            <br />
            Communication with APIs -<br />
            Simple I Swift | Dependable
          </p>
          <p className="mb-2 font-melody font-normal text-[#929fb1] text-[14px] tracking-[0.28px] leading-[22.4px]">
            Whether enhancing existing channels like SMS, Voice, Email, or
            adopting <br /> new ways  like WhatsApp, Bots, Social Media, we unify
            customer-preferred channels
          </p>
        </div>
      </div>
    </div>
  );
};

export default SectionTwo;
