import React from "react";
import Sales from "../assets/Sales.svg";
import Announce from "../assets/Announce.svg";
import reminder from "../assets/reminder.svg";
import DigitalMarketing from "../assets/DigitalMarketing.svg";
import "./sectionthree.css";

const SectionThree = () => {
  return (
    <div>
      {/* GRADIENT     */}

      <div className="flex w-[50.75rem] justify-center text-center mx-auto h-[55.438rem] rounded-[648.5px/483.5px] [background:radial-gradient(50%_50%_at_50%_50%,rgb(117,81,255)_0%,rgba(117,81,255,0)_100%)] opacity-[0.20]" />

      {/* HEADING     */}

      <div className=" relative w-[837px] h-[96px] mt-[-59rem] mx-auto">
        <p className="heading mx-auto my-auto mt-32 font-BoldMelo  gap-1 font-[525] text-white text-[35px] text-center tracking-[0.80px] leading-[normal]">
          Deliver Exceptional Customer Satisfaction
          <br />
          Throughout Their Entire Journey
        </p>
        <p className="paragraph w-[533px] font-BoldMelo font-normal text-center text-[#929fb1] text-[12px] mx-auto tracking-[0.28px] leading-[15.4px]">
          Make every conversation better - from giving personal help and
          creating <br />
          custom alerts to making logins easier and improving video meetings
        </p>
      </div>

      {/* cards  */}

      <div className="body relative">
        <div className="card-container">

           {/* card 1  */}
          <div className="card">
            <img
              src={DigitalMarketing}
              alt=""
              className="leading-10 w-14 h-12 card_img"
            />
            <h2 className="leading-10 text-white text-[20px] font-melody font-semibold">
              Marketing
            </h2>
            <p className="text-[12px] text-[#929fb1] font-melody">
              Hosed nomotion urboursanteres with <br /> a single click
            </p>
          </div>
          
           {/* card 2  */}

          <div className="card">
            <div>
              <img
                src={Announce}
                alt=""
                className="leading-10 w-14 h-12 card_img2"
              />
              <h2 className="leading-10 text-white  text-[20px] font-melody font-semibold">
                Announcement
              </h2>
              <p className="text-[12px] text-[#929fb1] font-melody">
                Pre-scheme you en at special date & <br /> time. It is the
                fastest way to reach your <br /> target audience
              </p>
            </div>
          </div>

           {/* card 3  */}

          <div className="card">
            <div>
              <img
                src={reminder}
                alt=""
                className="leading-10 w-14 h-12 card_img3"
              />
              <h2 className="leading-10 text-white text-[20px] font-melody font-semibold">
                Reminders
              </h2>
              <p className="text-[12px]  text-[#929fb1] font-melody">
                Reminder is the ultimate scheduling <br /> assistant,
                appointments and event <br /> reminders, with campaign sms{" "}
                <br />
                services
              </p>
            </div>
          </div>

           {/* card 4  */}

          <div className="card">
            <div>
              <img
                src={Sales}
                alt=""
                className="leading-10 w-14 h-12 card_img4"
              />
              <h2 className="leading-10 text-white  text-[20px] font-melody font-semibold">
                Sales
              </h2>
              <p className="text-[12px]  text-[#929fb1] font-melody">
                Two-way messaging, one-click video <br /> meetings and
                appointment <br />
                booking
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionThree;
