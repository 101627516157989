import React from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";

const GoogleMap = (props) => {
  return (
    <Map
      google={props.google}
      zoom={14}
      style={{ width: "50%", height: "50%" }}
      initialCenter={{ lat: 28.4595, lng: 77.0266 }}
    >
      <Marker name={"Current location"} />
    </Map>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyBeriMgLYLgjsrbapPnvJ6pIZrXykRc7p4",
})(GoogleMap);
