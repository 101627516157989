import React from "react";
import logo from "../assets/logo.svg";

const Footer = () => {
  return (
    <footer className="h-full bg-[#7551ff0a] border border-solid border-[#3725a5] shadow-[inset_100px_100px_80px_#7551ff29]">
      <div className="w-full justify-between mb-8 mt-8 mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
        <span className="flex text-sm text-gray-500 sm:text-center dark:text-gray-400">
          <img src={logo} className="h-5" alt="" />
          <a href="https://flowbite.com/" className=" font-melody text-center mt-[8px] ml-1 hover:underline">
            Nextin Cloud™
          </a>
        </span>

        <ul className="flex flex-wrap font-melody items-center mt-3 text-sm font-medium text-gray-500 dark:text-gray-400 sm:mt-0">
          <li>
            <a href="#" className="mr-4 hover:underline md:mr-6 ">
              Products
            </a>
          </li>
          <li>
            <a href="#" className="mr-4 hover:underline md:mr-6">
              Resources
            </a>
          </li>
          <li>
            <a href="#" className="mr-4 hover:underline md:mr-6">
              Company
            </a>
          </li>
          <li>
            <a href="#" className="mr-4 hover:underline md:mr-6">
              Terms & conditions
            </a>
          </li>

          <li>
            <a href="#" className="hover:underline md:mr-6">
              Privacy Policy
            </a>
          </li>
          
        </ul>
      </div>
    </footer>
  );
};
export default Footer;
