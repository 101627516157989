import React from "react";
import pvr from "../assets/pvr.png";
import dnd from "../assets/dnd.svg";
import Signup from "./Signup";
import FeatureSection from "../components/FeatureSection";
import SectionTwo from "../components/SectionTwo";
import SectionThree from "../components/SectionThree";
import Bulk from "../assets/Bulk.svg";
import whitel from "../assets/Whitelabel.svg";
import Vector from "../assets/Vector.svg";
import autothumb from "../assets/autothumb.svg";
import whtl from "../assets/whtl.svg";
import "../App.css";
import macbookoverlay from "../assets/macbookoverlay.png";
import SectionFour from "../components/SectionFour";
import "./Slider.css";


function Home() {
  return (
    <div className="flex flex-col items-center">
      {/* MAIN HEADING */}

      <div className="text-center z-10 font-BoldMelo font-medium text-white text-[50px] mx-auto mt-[6.5rem]">
        Where Connectivity
        <br />
        Meets Convenience
      </div>

      {/* SUB HEADING  */}

      <p className="relative font-melody z-10 font-normal text-[#929fb1]  text-[12px] text-center tracking-[0.28px] leading-[22.4px]">
        Experience the power of connection with us.
        <br />
        We seamlessly Connect, Engage &amp; Enhance your world
      </p>

      {/* BUTTON */}

      <button className="z-10 cursor-pointer mt-4 font-BoldMelo bg-gradient-to-r from-cyan-400 to-blue-500 items-center justify-center px-[22px] py-[10px] rounded-[8px] border border-solid border-[#b4a1ff] font-normal text-white text-[14px]   whitespace-nowrap">
        <div className="font-melody font-normal text-white text-[13px]">
          Start Today
        </div>
      </button>

      {/* GRADIENT */}

      <div className="w-full h-[61.438rem] mt-[-15rem] rounded-[648.5px/483.5px] [background:radial-gradient(50%_50%_at_50%_50%,rgb(117,81,255)_0%,rgba(117,81,255,0)_100%)] opacity-[0.25]" />

      {/* FEATURE IMAGE  */}

      <div className="relative mt-[-44rem] my-auto">
        <div className="w-full max-w-[900px] h-[34.313rem] bg-[100%_100%]">
          <img src={macbookoverlay} alt="" />
        </div>
      </div>

      {/* FEATURE SECTION */}
      <section className="">
      <div className="font-BoldMelo relative text-[22px] text-white text-center trust_sec">
            Trusted by
          </div>
        <div className="slider">
          
          <div className="slide-track">
            <div className="slide">
              <img src={pvr} height="50" width="80" alt="" />
            </div>
            <div className="slide">
              <img src={pvr} height="50" width="80" alt="" />
            </div>
            <div className="slide">
              <img src={pvr} height="50" width="80" alt="" />
            </div>
            <div className="slide">
              <img src={pvr} height="50" width="80" alt="" />
            </div>
            <div className="slide">
              <img src={pvr} height="50" width="80" alt="" />
            </div>
            <div className="slide">
              <img src={pvr} height="50" width="80" alt="" />
            </div>
            <div className="slide">
              <img src={pvr} height="50" width="80" alt="" />
            </div>
            <div className="slide">
              <img src={pvr} height="50" width="80" alt="" />
            </div>
            <div className="slide">
              <img src={pvr} height="50" width="80" alt="" />
            </div>
            <div className="slide">
              <img src={pvr} height="50" width="80" alt="" />
            </div>
            <div className="slide">
              <img src={pvr} height="50" width="80" alt="" />
            </div>
            <div className="slide">
              <img src={pvr} height="50" width="80" alt="" />
            </div>
            <div className="slide">
              <img src={pvr} height="50" width="80" alt="" />
            </div>
            <div className="slide">
              <img src={pvr} height="50" width="80" alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className="section_two_heading text-center font-melody text-white mx-auto mt-20">
        <div className="text-3xl font-BoldMelo">
          <h3>Evaluate. Connect. Excel</h3>
        </div>
        <div className="section_two_Para mt-4 text-[20px] leading-10 font-BoldMelo">
          <p>Supercharge your business with the Top-Notch Features</p>
        </div>
      </section>



      {/* CARD SECTION STARTS  */}


      <div className="container mt-14 font-BoldMelo mx-auto p-4 z-10">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
       
      
          <div className=" p-4 ">
            <img
              src={dnd}
              alt="Logo 2"
              className="mx-auto w-[46.32px] h-[53.33px]"
            />
            <p className="text-white text-[20px] text-center mt-7">
            DND Checks
            </p>
          </div>

       
          <div className=" p-4 ">
            <img
              src={Bulk}
              alt="Logo 2"
              className="mx-auto w-[46.32px] h-[53.33px]"
            />
            <p className="text-white text-[20px] text-center mt-7">
              Bulk Push on a Single Click
            </p>
          </div>

        
          <div className=" p-4 ">
            <img
              src={Vector}
              alt="Logo 3"
              className="mx-auto w-[46.32px] h-[53.33px]"
            />
            <p className="text-white text-[20px] text-center mt-7">
              Single API to Send OTP <br />
              on SMS and Email
            </p>
          </div>

         
          <div className=" p-4 ">
            <img
              src={autothumb}
              alt="Logo 4"
              className="mx-auto w-[46.32px] h-[53.33px]"
            />
            <p className="text-white text-[20px] text-center mt-7">
              Automatic Mechanism for <br />
              feedback over SMS and Voice
            </p>
          </div>

        
          <div className=" p-4">
            <img
              src={whtl}
              alt="Logo 5"
              className="mx-auto w-[46.32px] h-[53.33px]"
            />
            <p className="text-white text-[20px] text-center mt-7">
              White Label Reseller Panel
            </p>
          </div>

       
          <div className="p-4">
            <img src={whitel} alt="Logo 6" className="mx-auto h-16 w-16" />
            <p className="text-white text-[20px] text-center mt-7">
              White Label SMPP <br />
              Reseller Panel
            </p>
          </div>
        </div>
      </div>

      <div className="flex w-[68.75rem] h-[61.438rem] mt-[-26rem] rounded-[648.5px/483.5px] [background:radial-gradient(50%_50%_at_50%_50%,rgb(117,81,255)_0%,rgba(117,81,255,0)_100%)] opacity-[0.21]" />

      <div>
        <FeatureSection />
      </div>

      <div>
        <SectionTwo />
      </div>

      <div>
        <SectionThree />
      </div>

      <div>
        <SectionFour />
      </div>

      
      <div className=" w-[50.75rem] h-[25.438rem]  rounded-[648.5px/483.5px] [background:radial-gradient(50%_50%_at_50%_50%,rgb(117,81,255)_0%,rgba(117,81,255,0)_100%)] opacity-20" />

      <div className="">
        <Signup />
         {/* FORM IN SIGNUP COMPONENT  */}
      </div>
    </div>
  );
}

export default Home;
