import React from "react";
import GoogleMaps from "../components/GoogleMaps";
import toast, { Toaster } from 'react-hot-toast';

const SignupForm = () => {


  const diffToast = (e) => {
    e.preventDefault();
    toast.success('Message Submitted.');
  }


  return (
    <div>
    
      <div className="font-melody text-sm">
        <div className="text-center font-BoldMelo text-[16px] text-white mt-[-23rem] relative">
          <h1>Get In Touch</h1>
        </div>

        {/* <div className="text-[#929fb1] mx-auto justify-start">Oahfeo Workspaces 1095 Coworking Space Gurugram Sector 46 | <br />Huda Colony, Sector 46, Gurugram, Haryana</div> */}
        <div className="grid grid-cols-1 md:grid-cols-2  relative mb-10 ">
          {/* First Column (Map) */}
          <div className="flex flex-col">
            {/* <p className="mt-4 text-center  text-white">
            Oahfeo WorkSpace - 1095| Sec-46 <br />
            Ph:+91-1234567890
          </p> */}

            <div className="p-4 md:p-8 flex items-center justify-center">
              {/* Your Map Content Goes Here */}
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3508.4302285462372!2d77.04912997337648!3d28.43644449296213!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d191172e5422d%3A0xdf0acbbafc3d160c!2sOahfeo%20Workspaces%20-%20Frappe%20-%20Best%20Coworking%20Space%20Gurugram%20Sector%2046%20%7C%20Private%20Office%20on%20Rent%20%7C%20Virtual%20Office!5e0!3m2!1sen!2sin!4v1697870563460!5m2!1sen!2sin"
                width="500"
                height="250"
                className=" rounded-[20px]"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>

          {/* Second Column (Form) */}
          <div className="p-4">
            {/* Your Form Content Goes Here */}
            <form className=" p-4">
              {/* First Four Inputs */}
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <input
                    type="text"
                    size={75}
                    placeholder="First Name"
                    className="bg-[#7551ff0a] rounded-[10px] border border-solid border-[#3725a5] shadow-[inset_0px_0px_80px_#7551ff29] text-richblack-5 w-full p-[12px]"
                  />
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="Last Name"
                    className="bg-[#7551ff0a] rounded-[10px] border border-solid border-[#3725a5] shadow-[inset_0px_0px_80px_#7551ff29] text-richblack-5 w-full p-[12px]"
                  />
                </div>
                <div>
                  <input
                    type="email"
                    placeholder="Email ID"
                    className="bg-[#7551ff0a] font-melody rounded-[10px] border border-solid border-[#3725a5] shadow-[inset_0px_0px_80px_#7551ff29] text-richblack-5 w-full p-[12px]"
                  />
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="Phone Number"
                    className="bg-[#7551ff0a] font-melody rounded-[10px] border border-solid border-[#3725a5] shadow-[inset_0px_0px_80px_#7551ff29] text-richblack-5 w-full p-[12px]"
                  />
                </div>
              </div>

              {/* Message Input (Bigger) */}
              <input
                type="text"
                placeholder="Message"
                className="p-2 h-[100px] font-melody my-4 bg-[#7551ff0a] rounded-[10px] border border-solid border-[#3725a5] shadow-[inset_0px_0px_80px_#7551ff29] text-richblack-5 w-full"
              />

              {/* Submit Button */}
              <div className="flex justify-center ">
                <button
                  onClick={diffToast}
                  type="submit"
                  className="px-[1.8em] py-[0.8em] text-white font-melody mt-4 text-center cursor-pointer rounded-[7px] border border-solid border-[#b4a1ff] shadow-[inset_0px_0px_16px_#7551ffa3] bg-gradient-to-r from-cyan-400 to-blue-500"
                >
                  Submit
                </button>
                <Toaster />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupForm;
