import { Route, Routes } from "react-router-dom";
import "./App.css";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import Docs from "./components/Docs";
import Pricing from "./components/Pricing";
import Company from "./components/Company";
import MainHeader from "./components/MainHeader";
import { useState } from "react";
import TwoFactor from "./components/TwoFactor";
import Footer from "./components/Footer";


function App(props) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // let btn = "StartToday"

  return ( 
    <div className="w-full bg-richblack-1000 items-center">
      <Navbar isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
 
      

      <Routes>
        <Route path="/" element={<MainHeader />}>
          {/* Default Route  */}
          <Route index element={<Home />} />
          <Route path="/Docs" element={<Docs />}></Route>
          <Route path="/company" element={<Company />}></Route>
          <Route path="/pricing" element={<Pricing />}></Route>
          {/* <Route path= "/Contact" element={<Contact/>}></Route> */}
          <Route path="*" element={<div>PAGE NOT FOUND</div>}></Route>
          <Route path="/TwoFactor" element={<TwoFactor />}></Route>
        </Route>
      </Routes>

      <Footer/>
    </div>
  );
}

export default App;
