import React from "react";
import Twofactorauthentication from "../assets/Twofactorauthentication.svg";



const TwoFactor = () => {
  return (
    <div className="mt-20">
      <span className="text-white mx-auto ml-20 text-2xl">
        2 factor authentication
      </span>
      <div className="flex gap-[60px]">
        <p className="text-white mt-10 mx-auto ml-20 leading-6">
          Nextin Cloud is providing 2 Factor Authentication Services, where
          Two-factor authentication (2FA) OTP services are tailored for the
          immediate delivery of security codes through text messages and OTP on
          Call. This solution is well-suited for businesses seeking to offer
          robust security measures to safeguard their customers. Establish
          automatic SMS notifications and OTP emails by seamlessly integrating
          with APIs.
        </p>
        <img
          src={Twofactorauthentication}
          className="w-[350px] h-[350px] mt-[-1.5rem] mr-14"
          alt=""
        />
      </div>

      <button className="ml-20 relative px-[1.6em] py-[0.7em] text-center rounded-[8px] border border-solid border-[#b4a1ff] shadow-[inset_0px_0px_16px_#7551ffa3] [background:linear-gradient(180deg,rgb(86,64,218)_0.01%,rgb(86,64,218)_0.02%,rgb(127.09,120.56,239.02)_28.12%,rgb(88,162.06,249)_61.46%,rgb(41,233,245)_100%)]">
        <div className="font-melody font-normal text-white text-[12px]">
          Know More
        </div>
      </button>
    </div>
  );
};

export default TwoFactor;
