import React from "react";
// import sms from "../assets/sms.svg";
import featuresimg1 from "../assets/featuresimg1.png";
import "./Sectionfour.css";

const SectionFour = () => {
  return (
    <section>
      <div>
        <h1 className="relative mt-[5rem] font-BoldMelo text-center text-white">
          Why Nextin Cloud ?
        </h1>
        <div>
          <div className="flex flex-col md:flex-row justify-center items-center gap-14">
            {/* Left Div */}
            <div className="w-full md:w-1/2 text-center relative">
              <img
                src={featuresimg1}
                alt="Your Image"
                className="w-full sec_four_img  mb-4 border-solid rounded-[35px] max-w-[550px] h-[350px]"
              />
            </div>

            {/* Right Div */}
            <div>
              {/* 1 */}

              <div className="w-full text-center md:text-left text-white relative">
                <p className="mb-2 font-BoldMelo font-medium text-white text-[25px] tracking-[0.64px] leading-[normal] ">
                  Client Oriented
                </p>
                <p className="mb-2 font-BoldMelo font-normal text-[#929fb1] text-[14px] tracking-[0.28px] leading-[22.4px]">
                  Push SMS uses a top-tier promotional SMS <br /> gateway for  instant
                  delivery across all networks.
                </p>
              </div>

              {/* 2 */}

              <div className="w-full mt-6 text-center md:text-left text-white">
                <p className="mb-2 font-BoldMelo font-medium text-white text-[25px] tracking-[0.64px] leading-[normal] ">
                   Enthusiastic
                </p>
                <p className="mb-2 font-BoldMelo font-normal text-[#929fb1] text-[14px] tracking-[0.28px] leading-[22.4px]">
                We're deeply committed to our work, and our <br /> dedicated  team excels at achieving excellence.
                </p>
              </div>

              {/* 3 */}

              <div className="w-full mt-6 text-center md:text-left text-white">
                <p className="mb-2 font-BoldMelo font-medium text-white text-[25px] tracking-[0.64px] leading-[normal] ">
                  Trust
                </p>
                <p className="mb-2 font-BoldMelo font-normal text-[#929fb1] text-[14px] tracking-[0.28px] leading-[22.4px]">
                  We uphold transparency and professionalism <br /> through active
                  communication with all stakeholders.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionFour;
