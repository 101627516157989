import React from "react";
import whatsapp from "../assets/whatsapp.svg";
import smpp from "../assets/smpp.svg";
import Email from "../assets/Email.svg";
import voice from "../assets/voice.svg";
import DigitalMarketing from "../assets/DigitalMarketing.svg";
import sms from "../assets/sms.svg";

const FeatureSection = () => {
  return (
    <div>
      <div class=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 mt-[-30rem]">
        {/* BOX 1  */}
        <div className=" w-[320px] h-[350px] bg-[#7551ff0a] rounded-[40px] border border-solid border-[#3725a5] shadow-[inset_0px_0px_80px_#7551ff29]">
          <div className="w-full min-h-[15rem] relative cursor-pointer">
            <div className="z-[100] w-full h-full flex flex-col justify-between pl-5 py-6 pr-8">
              <div className="inline-flex flex-col items-start gap-[32px] relative ">
                <div className="inline-flex items-center gap-[31px] relative">
                  <img
                    className="relative w-[40.01px] h-[50px]"
                    alt=""
                    src={sms}
                  />
                  <div className=" w-full font-BoldMelo font-medium text-white text-[21px] tracking-[0.48px] leading-[normal]">
                    SMS
                  </div>
                </div>
                <p className="w-[247px] font-BoldMelo font-normal text-[#929fb1] text-[12px] tracking-[0.28px] leading-[18.4px]">
                  Send Messages with Nextin Cloud to increase your business
                  size. SMS is a communication service that allows businesses
                  and organizations to send a large volume of text messages to
                  multiple recipients simultaneously. It is commonly used for
                  various purposes,including marketing,notifications, alerts,and
                  reminders.
                </p>
              </div>

              <div className="cursor-pointer mt-4 w-5/12 items-center justify-center gap-[10px] px-[24px] py-[12px] relative flex-[0_0_auto] rounded-[8px] border border-solid border-[#b4a1ff] shadow-[inset_0px_0px_16px_#7551ffa3] bg-gradient-to-r from-cyan-400 to-blue-500">
                <div className="h-[14px] text-center font-BoldMelo font-normal text-white text-[14px] tracking-[0.28px] leading-[normal] whitespace-nowrap">
                  Explore
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* BOX 2  */}
        <div className=" w-[317px] h-[350px] bg-[#7551ff0a] rounded-[40px] border border-solid border-[#3725a5] shadow-[inset_0px_0px_80px_#7551ff29]">
          <div className="w-full min-h-[15rem] relative cursor-pointer">
            <div className="z-[100]  w-full h-full flex flex-col justify-between pl-5 py-6 pr-8">
              <div className="inline-flex flex-col items-start gap-[32px] relative ">
                <div className="inline-flex items-center gap-[31px] relative">
                  <img
                    className="relative w-[40.01px] h-[50px]"
                    alt=""
                    src={voice}
                  />
                  <div className="relative w-fit font-BoldMelo font-medium text-white text-[21px] tracking-[0.48px] leading-[normal]">
                    Voice
                  </div>
                </div>
                <p className="relative w-[247px] font-BoldMelo font-normal text-[#929fb1] text-[12px] tracking-[0.28px] leading-[18.4px]">
                  When providing voice services as a service to users, it's
                  crucial to focus on meeting their communication needs
                  effectively. Here are key points to consider when writing
                  about voice services as a user-centric offering: Click to
                  Call, Cloud IVR, Outbound Dialer, Missed Call, Services, Toll
                  Free Number
                </p>
              </div>

              <div className="cursor-pointer mt-8 w-5/12 items-center justify-center gap-[10px] px-[24px] py-[12px] relative flex-[0_0_auto] rounded-[8px] border border-solid border-[#b4a1ff] shadow-[inset_0px_0px_16px_#7551ffa3] bg-gradient-to-r from-cyan-400 to-blue-500">
                <div className="relative h-[14px] text-center font-BoldMelo font-normal text-white text-[14px] tracking-[0.28px] leading-[normal] whitespace-nowrap">
                  Explore
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* BOX 3 */}
        <div className="relative w-[317px] h-[350px] bg-[#7551ff0a] rounded-[40px] border border-solid border-[#3725a5] shadow-[inset_0px_0px_80px_#7551ff29]">
          <div className="w-full min-h-[15rem] relative cursor-pointer">
            <div className="z-[100] w-full h-full flex flex-col justify-between pl-5 py-6 pr-8">
              <div className="inline-flex flex-col items-start gap-[32px] relative ">
                <div className="inline-flex items-center gap-[31px] relative">
                  <img
                    className="relative w-[40.01px] h-[50px]"
                    alt=""
                    src={whatsapp}
                  />
                  <div className="relative w-fit font-BoldMelo font-medium text-white text-[21px] tracking-[0.48px] leading-[normal]">
                    Whatsapp
                  </div>
                </div>
                <p className="relative w-[247px] font-BoldMelo font-normal text-[#929fb1] text-[12px] tracking-[0.28px] leading-[18.4px]">
                  Efficiently answer customer questions with automated
                  responses. Keep clients updated on product or subscription
                  status. Share shipping details and confirm purchases. Use our
                  Verification API to stop fraud with phone number verification.
                </p>
              </div>

              <div className="cursor-pointer mt-12 w-5/12 items-center justify-center gap-[10px] px-[24px] py-[12px] relative flex-[0_0_auto] rounded-[8px] border border-solid border-[#b4a1ff] shadow-[inset_0px_0px_16px_#7551ffa3] bg-gradient-to-r from-cyan-400 to-blue-500">
                <div className="relative h-[14px] text-center [font-family:'BL_Melody-Regular',Helvetica] font-normal text-white text-[14px] tracking-[0.28px] leading-[normal] whitespace-nowrap">
                  Explore
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* BOX 4  */}
        <div className="relative w-[317px] h-[350px] bg-[#7551ff0a] rounded-[40px] border border-solid border-[#3725a5] shadow-[inset_0px_0px_80px_#7551ff29]">
          <div className="w-full min-h-[15rem] relative cursor-pointer">
            <div className="z-[100]  w-full h-full flex flex-col justify-between pl-5 py-6 pr-8">
              <div className="inline-flex flex-col items-start gap-[32px] relative ">
                <div className="inline-flex items-center gap-[31px] relative">
                  <img
                    className="relative w-[40.01px] h-[50px]"
                    alt=""
                    src={Email}
                  />
                  <div className="relative w-fit font-BoldMelo font-medium text-white text-[21px] tracking-[0.48px] leading-[normal]">
                    Email
                  </div>
                </div>
                <p className="relative w-[247px] font-BoldMelo font-normal text-[#929fb1] text-[12px] tracking-[0.28px] leading-[18.4px]">
                  Email services facilitate communication through electronic
                  messages. They offer features such as sending, receiving, and
                  organizing emails with user-friendly interfaces. These
                  services support attachments, filters, and spam protection,
                  ensuring efficient and secure communication
                </p>
              </div>

              <div className="cursor-pointer mt-7 w-5/12 items-center justify-center gap-[10px] px-[24px] py-[12px] relative flex-[0_0_auto] rounded-[8px] border border-solid border-[#b4a1ff] shadow-[inset_0px_0px_16px_#7551ffa3] bg-gradient-to-r from-cyan-400 to-blue-500">
                <div className="relative h-[14px] text-center font-BoldMelo font-normal text-white text-[14px] tracking-[0.28px] leading-[normal] whitespace-nowrap">
                  Explore
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* BOX 5  */}
        <div className="relative w-[317px] h-[350px] bg-[#7551ff0a] rounded-[40px] border border-solid border-[#3725a5] shadow-[inset_0px_0px_80px_#7551ff29]">
          <div className="w-full min-h-[15rem] relative cursor-pointer">
            <div className="z-[100] w-full h-full flex flex-col justify-between pl-5 py-6 pr-8">
              <div className="inline-flex flex-col items-start gap-[32px] relative ">
                <div className="inline-flex items-center gap-[31px] relative">
                  <img
                    className="relative w-[40.01px] h-[50px]"
                    alt=""
                    src={smpp}
                  />
                  <div className="relative w-fit [font-family:'BL_Melody-Medium',Helvetica] font-medium text-white text-[21px] tracking-[0.48px] leading-[normal]">
                    SMPP
                  </div>
                </div>
                <p className="relative w-[247px] [font-family:'BL_Melody-Regular',Helvetica] font-normal text-[#929fb1] text-[12px] tracking-[0.28px] leading-[18.4px]">
                  SMPP (Short Message Peer-to-Peer) services enable reliable and
                  high-speed SMS communication between applications and mobile
                  devices. These services offer direct, efficient messaging with
                  extensive delivery reporting, making them ideal for businesses
                  and SMS gateways.
                </p>
              </div>

              <div className="cursor-pointer mt-11 w-5/12 items-center justify-center gap-[10px] px-[24px] py-[12px] relative flex-[0_0_auto] rounded-[8px] border border-solid border-[#b4a1ff] shadow-[inset_0px_0px_16px_#7551ffa3] bg-gradient-to-r from-cyan-400 to-blue-500">
                <div className="relative h-[14px] text-center [font-family:'BL_Melody-Regular',Helvetica] font-normal text-white text-[14px] tracking-[0.28px] leading-[normal] whitespace-nowrap">
                  Explore
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* BOX 6  */}
        <div className="relative w-[317px] h-[350px] bg-[#7551ff0a] rounded-[40px] border border-solid border-[#3725a5] shadow-[inset_0px_0px_80px_#7551ff29]">
          <div className="w-full min-h-[15rem] relative cursor-pointer">
            <div className="z-[100] w-full h-full flex flex-col justify-between pl-5 py-6 pr-8">
              <div className="inline-flex flex-col items-start gap-[32px] relative ">
                <div className="inline-flex items-center gap-[31px] relative">
                  <img
                    className="relative w-[40.01px] h-[50px]"
                    alt=""
                    src={DigitalMarketing}
                  />
                  <div className="relative w-fit [font-family:'BL_Melody-Medium',Helvetica] font-medium text-white text-[21px] tracking-[0.48px] leading-[normal]">
                    DigitalMarketing
                  </div>
                </div>
                <p className="relative w-[247px] [font-family:'BL_Melody-Regular',Helvetica] font-normal text-[#929fb1] text-[12px] tracking-[0.28px] leading-[18.4px]">
                  Digital marketing services promote businesses online through
                  strategies like SEO, social media, email, and content
                  marketing to increase brand visibility, engagement, and
                  conversions.
                </p>
              </div>

              <div className="cursor-pointer mt-20 w-5/12 items-center justify-center gap-[10px] px-[24px] py-[12px] relative flex-[0_0_auto] rounded-[8px] border border-solid border-[#b4a1ff] shadow-[inset_0px_0px_16px_#7551ffa3] bg-gradient-to-r from-cyan-400 to-blue-500">
                <div className="relative h-[14px] text-center [font-family:'BL_Melody-Regular',Helvetica] font-normal text-white text-[14px] tracking-[0.28px] leading-[normal] whitespace-nowrap">
                  Explore
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureSection;
