import React from "react";
import logo from "../assets/logo.svg";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import arrowdown from "../assets/arrowdown.svg";
import { useState } from "react";

import "./Navbar.css"




function Navbar(props) {

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };



  let isLoggedIn = props.isLoggedIn;
  let setIsLoggedIn = props.setIsLoggedIn;

  const [open, setOpen] = useState(false);



  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  


  return (

    <div className="header">
   <div className="navbar">
   <div className="flex">
         <Link>
          <img
            src={logo}
            alt=""
            width="53.03px"
            height="30.99px"
            loading="lazy"
            className="mt-[15px]"
          />
        </Link>
        <span className="nav-text flex mt-[30px] font-BoldMelody font-medium text-white text-[14px] tracking-[0.32px] leading-[normal] ml-1 whitespace-nowrap">
          Nextin Cloud
        </span>
      </div>


      <nav className="links">
        <ul className="mt-[25px] mx-auto font-melody text-[#929fb1]  font-semibold inline-flex items-center text-[13px] tracking-[0.28px] gap-6 leading-[normal]">
          <div className="group inline-block relative">
            <button className="links text-[#929fb1] font-semibold inline-flex items-center">
              <li>Product</li>
              <svg
                className="fill-current h-4 w-4 group-hover:rotate-180 transition-transform"
                xmlns={arrowdown}
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </button>
            <ul className="absolute hidden text-gray-700 pt-1 group-hover:block">
              <li>
                <Link
                  to="/TwoFactor"
                  className="rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap"
                >
                  2 FA
                </Link>
              </li>

              <li>
                <Link
                  className="bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap"
                  href="#"
                >
                  Two
                </Link>
              </li>

              <li>
                <a
                  className="rounded-b bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap"
                  href="#"
                >
                  Three
                </a>
              </li>
            </ul>
          </div>

          <div className="group inline-block relative">
            <button className="links text-[#929fb1] font-semibold inline-flex items-center">
              <li>Resources</li>
              <svg
                className="fill-current h-4 w-4 group-hover:rotate-180 transition-transform"
                xmlns={arrowdown}
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </button>
            <ul class="absolute hidden text-gray-700 pt-1 group-hover:block">
              <li>
                <a
                  className="rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap"
                  href="#"
                >
                  One
                </a>
              </li>

              <li>
                <a
                  className="bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap"
                  href="#"
                >
                  Two
                </a>
              </li>

              <li>
                <a
                  className="rounded-b bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap"
                  href="#"
                >
                  Three
                </a>
              </li>
            </ul>
          </div>

          <div className="group inline-block relative">
            <button className="links text-[#929fb1] font-semibold inline-flex items-center">
              <li>Usability</li>
              <svg
                className="fill-current h-4 w-4 group-hover:rotate-180 transition-transform"
                xmlns={arrowdown}
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </button>
            <ul class="absolute hidden text-gray-700 pt-1 group-hover:block">
              <li>
                <a
                  className="rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap"
                  href="#"
                >
                  One
                </a>
              </li>

              <li>
                <a
                  className="bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap"
                  href="#"
                >
                  Two
                </a>
              </li>

              <li>
                <a
                  className="rounded-b bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap"
                  href="#"
                >
                  Three
                </a>
              </li>
            </ul>
          </div>

          <li className="">
            <Link to="/Docs">API Docs</Link>
          </li>

          <li className="">
            <Link to="/Company">Company</Link>
          </li>

          <li className="">
            <Link to="/Pricing">Pricing</Link>
          </li>

          <li className="">
            <Link to="/contact">Contact Us</Link>
          </li>
        </ul>
      </nav>
      

    {/* login - signup - Logout */}
    <div className="nav links flex mt-8 gap-10 font-melody text-white text-xs">
          {!isLoggedIn && (
          <Link to="/login">
           <button className="w-fit font-normal text-white text-[14px] tracking-[0.28px] leading-[normal]">
                Login
              </button>
            </Link>
         )}

          {!isLoggedIn && (
            <Link to="/Signup">
            <button className="action_btn font-BoldMelo bg-gradient-to-r from-cyan-400 to-blue-500 items-center justify-center px-[24px] py-[8px] rounded-[8px] border border-solid border-[#b4a1ff]  mt-[-10px] font-normal text-white text-[14px]   whitespace-nowrap">
                Start Trail
              </button>
            </Link>
          )}
          {isLoggedIn && (
            <Link to="/">
              <button
                onClick={() => {
                  setIsLoggedIn(false);
                  toast.success("Logged Out");
                }}
              >
                Log Out
              </button>
            </Link>
          )}
        
        </div> 
   </div> 

       <div className="relative">
      {isOpen && (
        <div
          onClick={closeMenu}
          className="fixed top-0 left-0 w-full h-full bg-gray-700 opacity-50 z-20"
        ></div>
      )}
      <button
        onClick={toggleMenu}
        className={`menu_btn text-white rounded-full shadow-md z-30 ${isOpen ? 'hidden' : ''}`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </button>
      <div
        className={`fixed top-0 right-0 h-screen w-2/3 md:w-1/3 bg-richblack-500 shadow-md z-30 transform transition-transform duration-200 ${isOpen ? 'translate-x-0' : 'translate-x-full'}`}
      >
      
        <button
          onClick={toggleMenu}
          className="p-3 m-4 absolute top-0 right-0 text-white rounded-full shadow-md z-40"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <ul className="mt-20 links ml-10">
          <div className="text-white p-4 mt-[-3.5rem] font-BoldMelo">Menu</div>
          <li className="mb-1 mt-10">
            <a href="#" className="block p-4 links font-melody">Product</a>
          </li>
          <li className="mb-1">
            <a href="#" className="block p-4 links font-melody">Resources</a>
          </li>
          <li className="mb-1">
            <a href="#" className="block p-4 links font-melody">Usability</a>
          </li>
          <li className="mb-1">
            <a href="#" className="block p-4 links font-melody">API</a>
          </li>
          <li className="mb-1">
            <a href="#" className="block p-4 links font-melody">Comapany</a>
          </li>
          <li className="mb-1">
            <a href="#" className="block p-4 links font-melody">Pricing</a>
          </li>
          <li className="mb-1">
            <a href="#" className="block p-4 font-melody">Login</a>
          </li>
        </ul>
      </div>
    </div>
</div>

  );
}

export default Navbar;
